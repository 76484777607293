require('@fancyapps/fancybox')
import VideoBackgrounds from './jquery.youtube-background'

$('.button-mobile').click(function (e) {
  e.preventDefault();
  //get global video player object
  let player = window.player
  player.pause();
  $('.landing-page').addClass('step2')
})

new VideoBackgrounds('[data-youtube]');

$('.form-has-error').each( function () {
  $.fancybox.open({
    src  : '#modalFormRegister',
  });
})
